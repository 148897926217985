var __jsx = React.createElement;
import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import CookieConsent from '../cookie/CookieConsent';
import styles from '../styles/account/Account.module.scss';

var ConsoSmallFooter = function ConsoSmallFooter() {
  var year = new Date();
  return __jsx("div", {
    className: styles.footer
  }, __jsx(Container, null, __jsx(Row, {
    className: "mx-auto"
  }, __jsx(Col, {
    xs: "12",
    className: "copyright py-3 text-center"
  }, __jsx("p", {
    className: ""
  }, "@Copyright ", year.getFullYear(), " Conso Media Group. Toate drepturile rezervate")))), __jsx(CookieConsent, null));
};

export default ConsoSmallFooter;