export const getAgeFromBirthday = (birthday) => {
    // birthday - dd/mm/yyyy
    
    let rxDatePattern = /^(\d{1,2})(\/|-)(\d{1,2})(\/|-)(\d{4})$/; //Declare Regex
    let dtArray = birthday.match(rxDatePattern); // is format OK?
    let dtMonth = dtArray[3];
    let dtDay= dtArray[1];
    let dtYear = dtArray[5];  
    let ageDate = new Date(new Date() - new Date(dtYear, dtMonth-1, dtDay) ); // miliseconds from epoch
    let years =  Math.abs(ageDate.getUTCFullYear() - 1970);
    return years;
}

export const orderList = (list, field, order) => {
    if (order == 'ASC') {
       list.sort( (a, b) =>  a[field] - b[field] );
    } else {
        list.sort( (a, b) => b[field]- a[field]);
    }
    return list;

}

export const formatDate = (data, delimiter) => {
    // from date to dd-mm-yyyy
    let year = data.getFullYear();
    let month = data.getMonth() + 1 ;
    let day = data.getDate();
    return (day<10 ? '0' + day : day) + delimiter + (month<10 ? '0' + month : month) + delimiter + year;
}

export const reverseDate  = (data) => {
    // convert from dd-mm-yyy to yyy-mm-dd and back
    let dataExtract = data.split('-');
    return dataExtract[2] + '-' + dataExtract[1] + '-' + dataExtract[0];
}


export const getAgeFromCNP = (value) => {
    let an = parseInt(value.substring(1,3));
    let sex = parseInt(value.substring(0,1));
    switch( sex ) {
        case 1  : case 2 : { an += 1900; } break;
        case 3  : case 4 : { an += 1800; } break;
        case 5  : case 6 : { an += 2000; } break;
        case 7  : case 8 : case 9 : 
            an += 2000;  
            if( an > ( parseInt( new Date().getYear() , 10 ) - 14 ) ) { 
                an -= 100; 
            } 
            break;
        default : { 
            return  false; 
        }
    }

    let luna = parseInt(value.substring(3,5));
    let zi = parseInt(value.substring(5,7));
    let birthDate = new Date(an, luna-1, zi );
    
    let currentdate = new Date(); 
    return  currentdate.getFullYear() - birthDate.getFullYear();
}

export const createDateFromFormat = (string) =>{
    let data = string.split('-');
    let month = data[1];
    let monthNo = parseInt(month[0] == '0' ? month[1] : month) ;
   
    let response = new Date();
    response.setMonth( monthNo -1, data[0]);
    response.setFullYear(data[2]);
    return response;
}

export const longMonthList = [
    'ianuarie', 'februarie', 'martie', 'aprilie', 'mai', 'iunie', 'iulie', 'august', 'septembrie', 'octombrie', 'noiembrie', 'decembrie'
]

export const showRoDateAndTime = (data) =>{
    
    if ( data instanceof Date) {
        let res = data.getDate() + ' ';
        res += longMonthList[data.getMonth()] + ' ';
        res += data.getFullYear() + ' ';
        res += data.getHours()+ ':';
        let minutes = data.getMinutes();
        res +=  minutes < 10 ? '0'+minutes : minutes;
        return res;
    }
    return false;
}

export const getDateTimeFromString = (data) => {
    //d-m-Y H:i
    const parts = data.split(' ');

    let res = createDateFromFormat(parts[0]);
    const time = parts[1].split(':');
    res.setHours(time[0]);
    res.setMinutes(time[1]);
    return res;
}
