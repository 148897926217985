import React, {useState, useEffect} from 'react';
import {useRouter} from 'next/router';
import ConsoHead from '../components/header/ConsoHead';
import ConsoNavbar from '../components/header/Navbar';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ConsoFooter from '../components/footer/ConsoSmallFooter';
import {accountMenuList} from '../components/header/mainMenuList';
import {useSelector, useDispatch} from 'react-redux';
import AuthScreen from '../components/elements/AuthScreen';
import AccountDashboard from '../components/account/dashboard/AccountDashboard';
import { loadCartFromStorage } from '../components/utils/general/cartUtils';
import { saveProducts } from '../components/cart/cartSlice';

const Index = () =>{
   
    const seo  = {
        title: "Contul Conso",
        description: "Contul de utilizator Conso",
        canonical: process.env.NEXT_PUBLIC_SITE_URL
    };

    const user = useSelector( state => state.user);
    const shoppingCart = useSelector(state => state.cart);
    const cart = shoppingCart.products;

    const [isLoading, editIsloading] = useState(true);
    const closeLoading = () => { editIsloading(false)};
    const router = useRouter();
    const dispatch = useDispatch();

    useEffect( ()=>{
            setupCart();
        }, []
    )

    useEffect( ()=>{
        if (user.status=='logout') {
            router.push('/login');
        }
    }, [user.status]
    )


    const loadingScreen = 
        <AuthScreen 
            message="Te rugăm să aștepți..." 
            closeLoading={closeLoading}
        />;
    const setupCart = () => {
        if (cart && cart.length == 0) {
            const storageData = loadCartFromStorage();
            if (storageData && storageData.products) {
                dispatch(saveProducts({ products: storageData.products, status: 'init'}));
            }
        }
    }
 
    return (
        isLoading ?
        loadingScreen
        :
         <>
            <ConsoHead seo={seo} />
            <ConsoNavbar selected="Contul meu" menu={accountMenuList} cart={cart} /> 
            <div className="mb-0 pb-0 bg-home" id="main"  >
                <Container>
                    <Row>
                        <Col xs={12} lg={8} className="mx-auto">
                            <h1 className="heading1 py-3 font-weight-bold text-center black-text">
                                Bun venit {user.firstName},
                            </h1>
                        </Col>     
                    </Row>
                    <Row>
                        <Col xs={12} lg={8} className="mx-auto">
                            <AccountDashboard />
                        </Col>
                    </Row>
                </Container>
            </div>   
            <ConsoFooter />
        </>
    );
}

export default Index;

