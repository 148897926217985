import { getDateTimeFromString } from "./Utilities";
export var saveCartToStorage = function saveCartToStorage(data) {
  localStorage.setItem('cart', JSON.stringify(data));
};
export var loadCartFromStorage = function loadCartFromStorage() {
  return JSON.parse(localStorage.getItem('cart'));
};
export var isCartProductExpired = function isCartProductExpired(product) {
  var type = product.type;
  var now = new Date();
  var isExpired = false;

  switch (type) {
    case 'rca':
      var expiresAt = getDateTimeFromString(product.expiresAt);
      isExpired = now > expiresAt ? true : false;
      break;
  }

  return isExpired;
};
export var getDeliveryAddressFromRCA = function getDeliveryAddressFromRCA(vehicle) {
  var address = vehicle.address,
      owner = vehicle.owner;
  var addressName = "Str. ".concat(address.strada);
  if (address.nrStrada != '') addressName += " nr. ".concat(address.nrStrada);
  if (address.bloc != '') addressName += ", Bloc ".concat(address.bloc);
  if (address.scara != '') addressName += ", Scara ".concat(address.scara);
  if (address.etaj != '') addressName += ", Etaj ".concat(address.etaj);
  if (address.apartament != '') addressName += ", Ap. ".concat(address.apartament);
  return {
    county: address.Judet.name,
    city: address.Localitate.name,
    phone: owner.Telefon,
    address: addressName,
    postal_code: address.cod_postal,
    fname: owner.Prenume,
    lname: owner.Nume
  };
};
export var getDeliveryAddressFromAccount = function getDeliveryAddressFromAccount(user) {
  return {
    county: user.county ? user.county : '',
    city: user.city ? user.city : '',
    phone: user.phone ? user.phone : '',
    address: '',
    postal_code: '',
    fname: user.firstName ? user.firstName : '',
    lname: user.lastName ? user.lastName : ''
  };
};
export var totalProductValue = function totalProductValue(cart) {
  var total = cart.reduce(function (total, item) {
    return item.type == 'rca' ? total + parseFloat(Number(item.prima).toFixed(2)) : total;
  }, 0);
  return Number(total).toFixed(2);
};
export var productsOrderApi = function productsOrderApi(cart) {
  var products = cart.map(function (p) {
    var res;

    switch (p.type) {
      case 'rca':
        res = {
          type: p.type,
          identificator_oferta: p.identificator_oferta,
          id: p.id
        };
        break;

      default:
        res = p;
        break;
    }

    return res;
  });
  return products;
};