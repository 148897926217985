var __jsx = React.createElement;
import React from 'react';
import Link from 'next/link';
import Image from 'next/image';
import styles from '../../styles/account/AccountInfo.module.scss';
import { Col, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faFileAlt } from '@fortawesome/free-solid-svg-icons';

var DashboardItem = function DashboardItem(props) {
  var details = props.details;
  return __jsx(Col, {
    className: "p-3 mb-3",
    xs: 12,
    md: 6
  }, __jsx("div", {
    className: "".concat(styles.infoBox, " ").concat(styles.dash, " h-100 text-center p-3")
  }, __jsx("div", {
    className: "".concat(styles.title, " mb-0 text-center")
  }, details.title), __jsx(Link, {
    href: details.link
  }, __jsx("a", {
    title: details.title
  }, details.type == 'image' ? __jsx(Image, {
    layout: "fixed",
    src: details.thumb,
    alt: details.title,
    width: details.width,
    height: details.height,
    className: styles.imgLink
  }) : __jsx(FontAwesomeIcon, {
    icon: details.thumb,
    height: "32",
    "with": "32",
    size: "3x",
    className: "green"
  }))), __jsx("div", {
    className: "".concat(styles.itemInfo, " text-left")
  }, details.info), __jsx("p", {
    className: "mb-0"
  }, __jsx(Link, {
    href: details.link
  }, __jsx("a", {
    title: details.title,
    className: styles.itemDetails
  }, __jsx(FontAwesomeIcon, {
    icon: faChevronRight,
    height: "16",
    width: "16",
    className: "mr-2"
  }), "Detalii")))));
};

export default DashboardItem;