var __jsx = React.createElement;
import React, { useState } from 'react';
import Link from 'next/link';
import NavbarLinks from './NavbarLinks';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import UserTab from './UserTab';
import Image from 'next/image';
import CookieConsent from '../cookie/CookieConsent';
import MobileMenu from './MobileMenu';
import styles from '../styles/header/Navbar.module.scss';
import CartTab from './cart/CartTab';

var ConsoNavbar = function ConsoNavbar(props) {
  var menu = props.menu,
      selected = props.selected,
      cart = props.cart;

  var _useState = useState(false),
      isOpen = _useState[0],
      editIsOpen = _useState[1];

  var toggleMobileMenu = function toggleMobileMenu() {
    if (!isOpen) {
      document.querySelector("body").classList.add('mobile-menu');
    } else {
      document.querySelector("body").classList.remove('mobile-menu');
    }

    editIsOpen(!isOpen);
  };

  var closeMenu = function closeMenu() {
    editIsOpen(false);
    document.querySelector("body").classList.remove('mobile-menu');
  };

  return __jsx(React.Fragment, null, __jsx(CookieConsent, null), __jsx(Navbar, {
    className: "main-nav",
    expand: "lg"
  }, __jsx(Link, {
    href: process.env.NEXT_PUBLIC_WEB,
    passHref: true
  }, __jsx(Navbar.Brand, {
    target: "_blank",
    className: "ml-lg-3 mr-auto d-flex align-items-center ".concat(styles.logo)
  }, __jsx(Image, {
    src: "/images/icons/conso.png",
    width: 111,
    height: 33,
    alt: "Conso"
  }))), __jsx(Navbar.Toggle, {
    "aria-controls": "basic-navbar-nav",
    className: "order-2",
    onClick: toggleMobileMenu
  }), __jsx(CartTab, {
    cart: cart
  }), __jsx(UserTab, null), __jsx(Navbar.Collapse, {
    id: "basic-navbar-nav",
    className: "order-lg-1 order-2 d-none d-lg-block"
  }, __jsx(Container, {
    className: "justify-content-lg-center"
  }, __jsx(NavbarLinks, {
    menuList: menu,
    selected: selected
  })))), isOpen && __jsx(MobileMenu, {
    menuList: menu,
    closeMenu: closeMenu
  }));
};

export default ConsoNavbar;