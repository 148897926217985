var __jsx = React.createElement;
import React from 'react';
import Image from 'next/image';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
import styles from '../styles/elements/Loding.module.scss';

var LoadingFullPage = function LoadingFullPage(props) {
  var message = props.message,
      showLogo = props.showLogo;
  return __jsx(Row, {
    className: " h-100 ".concat(styles.fullPage)
  }, __jsx(Col, {
    className: "mb-5 d-flex align-items-center justify-content-center flex-column ".concat(styles.full_screen_msg)
  }, showLogo && __jsx("div", {
    className: "mx-auto mb-5"
  }, __jsx(Image, {
    src: "/images/icons/conso.png",
    width: 111,
    height: 33,
    classes: "",
    alt: "Conso"
  })), __jsx(Spinner, {
    animation: "border",
    variant: "success",
    size: "lg",
    className: "mr-2"
  }), __jsx("p", null, message)));
};

export default LoadingFullPage;